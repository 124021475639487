@import url("https://fonts.googleapis.com/css?family=Fredoka&display=swap");

:root {
  --dark-blue: #003964;
  --background-color: #b0dde7;
  --light-color: #faf7f3;
  --button-color: #034e78;
  --button-shadow: #022e47;
  --accent-color: #ffd140;
  --accent-color-lighter: #fedc81;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul {
  padding: 0;
}

input {
  color: var(--dark-blue);
}

button {
  all: unset;
}

body {
  font-family: "Fredoka";
  text-rendering: optimizeSpeed; /*This makes the text look better on iOS*/
  margin: 0;
  padding: 0;
  background-color: var(--light-color);
  min-height: 100vh; /* For older browsers */
  min-height: 100svh;
  color: var(--dark-blue);
}

header {
  background-color: var(--light-color);
  color: #044a80;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-shadow: 0 3px 5px rgba(41, 94, 192, 0.05);
  height: 86px;
  font-size: 14px;
}

.header-logo {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #005596;
}

header .info-button {
  margin-left: auto;
  margin-right: 14px;
  width: 24px;
  height: 24px;
  border: 3px solid #c8c8c8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c8c8c8;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
}

.wave-bottom {
  --mask: radial-gradient(
        50.47px at 50% calc(100% - 68.4px),
        #000 99%,
        #0000 101%
      )
      calc(100% - 48px) 0/96px 100%,
    radial-gradient(50.47px at 50% calc(100% + 44.4px), #0000 99%, #000 101%)
      100% calc(100% - 24px) / 96px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding-bottom: 28px; /* To move the content above the waves */
}

#logo-image {
  max-height: 33px;
  width: auto;
  height: auto;
  margin-right: 12px;
}

#quiz-container-and-header-wrapper {
  background-color: var(--background-color);
  min-height: 100vh;
  min-height: 100svh;
}

#quiz-container {
  margin: auto;
  max-width: 500px;
  overflow: hidden;
  padding: 15px 20px 0px;
  padding-top: 0px; /* If wave in header */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px - 6px - 6px); /* For older browsers */
  min-height: calc(100svh - 80px - 6px - 6px);
}

#quiz-container h1,
#quiz-container h2,
#quiz-container h3 {
  text-align: center;
}

#quiz-container h2 {
  font-size: 1.5rem;
}

#quiz-container h3 {
  font-size: 1.3rem;
}

#question-image {
  max-width: 100%;
  max-height: 36vh;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
}

.question {
  padding: 0px 20px;
  width: 100%;
}

#quiz-container .question-number {
  margin: 0;
  padding: 16px 30px;
  margin-bottom: 10px;
  font-size: 1.6rem;
}

.question p {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
}

@media (max-height: 880px) {
  .question p {
    font-size: 1.2rem;
    line-height: 1.45rem;
  }
}

#answers-container,
#category-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.answer-box,
.category-box {
  padding: 14px 25px 14px 14px;
  margin-bottom: 10px;
  background-color: var(--light-color);
  border-radius: 28px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--dark-blue);
  box-shadow: 0 4px 6px rgba(51, 103, 163, 0.2);
  display: flex;
  width: 100%;
  line-height: 1.8rem;
  font-size: 1.3rem;
  font-weight: bold;
}

@media (max-height: 880px) {
  .answer-box {
    padding: 10px 25px 10px 10px;
    border-radius: 26px;
    border-radius: calc((1.6rem + 20px) / 2);
    font-size: 1.15rem;
    line-height: 1.4rem;
  }
}

.category-box {
  padding: 14px 25px;
  align-items: center;
  justify-content: center;
}

.category-list {
  width: 90%;
}

.selected {
  background-color: var(--accent-color-lighter);
}

.answer-text {
  margin: auto 0;
}

.number-container {
  margin-right: 1rem;
  display: flex;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-color);
  border-radius: 50%;
  color: #ffffff;
  font-weight: bold;
  margin-right: 5px;
  text-align: center;
  font-size: 1.1rem;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  max-height: 2rem;
  max-width: 2rem;
}

@media (max-height: 700px) {
  .circle {
    font-size: 1rem;
    line-height: 1.8rem;
    height: 1.8rem;
    width: 1.8rem;
    max-height: 1.8rem;
    max-width: 1.8rem;
  }
}

/* Makes number 1 look better centered based on its optical center.
Please, change this based on the font chosen. */
.answer-box:first-child .number-container .circle p {
  position: relative;
  left: -2%;
}

.selected .circle {
  background: var(--light-color);
  color: var(--accent-color);
}

.action-button {
  padding: 14px 40px;
  margin-top: 12px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  border-radius: 25px;
  background-color: var(--button-color);
  /*background: linear-gradient(#296a96 0%, var(--button-color) 40%);*/
  color: #fff;
  min-width: 80px;
  max-width: 300px;
  cursor: pointer;
  box-shadow: var(--button-shadow) 0px 5px 0 0;
  text-align: center;
}

@media (max-height: 800px) {
  .action-button {
    margin-top: 5px;
    padding: 12px 40px;
  }
}

.action-button:disabled {
  background-color: #afafaf;
  background: #afafaf;
  color: #c8c8c8;
  box-shadow: #888 0px 5px 0 0;
}

.action-button:not([disabled]):active {
  box-shadow: var(--button-shadow) 0px 3px 0 0;
  transform: translate(0px, 2px);
}

#secret-number-input {
  padding: 12px 22px;
  margin-bottom: 10px;
  font-size: 2.5rem;
  font-family: "Fredoka";
  letter-spacing: 0.1rem;
  border-radius: 25px;
  border: 4px solid var(--dark-blue);
  text-align: center;
  background-color: (--light-color);
}

#secret-number-input.incorrect {
  border-color: #f85d48;
}

.shake {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  20%,
  60% {
    transform: translateX(-10px);
  }
  40%,
  80% {
    transform: translateX(10px);
  }
}

.result-numbers-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.result-number {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1c6c6;
  border-radius: 50%;
  border: 3px solid;
  font-weight: bold;
  margin-right: 5px;
  font-size: 1.1rem;
  line-height: 2.1rem;
  height: 2.4rem;
  width: 2.4rem;
  max-height: 2.4rem;
  max-width: 2.4rem;
  z-index: 2;
}

.result-number.correct {
  border-color: #48b479;
  background-color: #aee7c8;
}

.result-number.incorrect {
  border-color: #ff5050;
}

.result-number.incorrect:before,
.result-number.incorrect:after {
  position: absolute;
  content: "";
  background: #ff505083; /* Color of the cross*/
  width: 2.4rem; /* Size of the cross*/
  height: 3px; /* The thickness of the line*/
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: -1;
}

.result-number.incorrect:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modal-backdrop {
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #032b426c;
  z-index: 10;
}

.modal-content-wrapper {
  position: fixed;
  width: min(100vw - 20px, 640px);
  max-height: calc(100vh - 20px);
  background-color: var(--light-color);
  margin: auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 36px;
  border-radius: 25px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
}

@media (max-height: 932px) {
  .modal-content-wrapper {
    position: fixed;
    width: min(100vw - 20px, 500px);
    min-height: calc(100vh);
    background-color: var(--light-color);
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    padding: 36px;
    border-radius: 0px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }
}

.modal-content {
  max-width: 500px;
  margin: auto;
}

.info {
  font-size: 1.3rem;
}

.info h1 {
  margin-top: 10px;
  font-size: 2.4rem;
}

@media (max-width: 368px) {
  .info h1 {
    font-size: 2rem;
  }
}

@media (max-width: 300px) {
  .info h1 {
    font-size: 1.6rem;
  }
}

.info ol {
  list-style-type: none; /* Remove default numbering */
  counter-reset: my-counter; /* Reset the counter */
  padding-left: 8px;
  margin-bottom: 36px;
}

.info ol li {
  counter-increment: my-counter; /* Increment the counter */
  margin-bottom: 24px;
}

.info ol li::before {
  content: counter(my-counter) ". "; /* Add the counter value before each list item */
  font-weight: bold;
}

.info p {
  margin-block-start: 24px;
}

#footer {
  background-color: var(--light-color);
  color: #888;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

#footer-logo-image {
  height: 20px;
}

/** AdminPage **/

#login-container {
  font-size: 1.2rem;
}

#login-container input {
  font-size: 1rem;
}

.large-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 20px;
  background-color: #044a80;
  color: #fff;
  min-width: 80px;
  max-width: 300px;
  cursor: pointer;
  text-align: center;
}

.log-in-container {
  width: 300px;
  padding: 30px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  text-align: center;
}

.popup-container {
  text-align: center;
}

#username {
  margin-top: 10px;
  margin-bottom: 20px;
}

#password {
  margin-bottom: 25px;
}

.log-in-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 20px;
  background-color: var(--button-color);
  color: #fff;
  min-width: 80px;
  max-width: 300px;
  cursor: pointer;
  text-align: center;
}

/** Dashboard Component**/

.page-container {
  position: relative;
  width: 90%;
  padding-left: 10%;
  padding-top: 50px;
}

.create-container {
  position: absolute;
  right: 5px;
}

.page-container table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 20px;
}

.page-container table td {
  border-radius: 20px;
  text-align: center;
}

.quiz-list h2 {
  margin: 26px 0 0 0;
}

.td-transparent {
  background-color: transparent;
}

.td-color {
  background-color: #f9f9f9;
  width: 90%;
}

.edit-del-button {
  width: 90%;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #044a80;
  color: #fff;
  text-align: center;
}

.edit-del-button:hover,
.new-quiz-button:hover {
  background-color: #af4343;
}

.new-quiz-button {
  padding: 10px 20px;
  border-radius: 20px;
  font-size: large;
  cursor: pointer;
  background-color: #0e6aa7;
  color: #fff;
  text-align: center;
}

.alert-container {
  text-align: center;
}

/** LoginHeader Component**/

.dashboard-header {
  height: 120px;
}

.welcome-text {
  margin-left: 5%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 20px; /* Adjust the distance from the right */
  top: 20px; /* Adjust the distance from the top */
}

@media (min-width: 100px) {
  .bm-burger-button {
    right: 50px; /* Adjust for larger screens */
    top: 30px; /* Adjust for larger screens */
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #044a80;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  right: 12px !important;
  top: 12px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 400px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #faf7f3;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  color: #044a80;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #dfbc4c;
}

.bm-overlay {
  background: rgba(0, 15, 30, 0.3) !important;
}

/* ChangePassword Component*/

.form-container {
  width: 360px;
  padding: 40px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 50px auto;
  text-align: center;
}

.form-container .input {
  text-align: center;
}

.form-container input {
  width: 100%;
}

.form-group-1,
.form-group-2 {
  padding-top: 10px;
  padding-bottom: 30px;
}

.change-password-button {
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #044a80;
  color: #fff;
  text-align: center;
  margin-right: 10px;
}

.text {
  text-align: left;
}

/* QuizForm Component*/

.button-container {
  padding-top: 10px;
  padding-right: 50px;
  text-align: right;
}

.go-back-button {
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #044a80;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 20px;
}

.quiz-form-container {
  width: 70%;
  max-width: 900px;
  padding: 20px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding-left: 100px;
  margin-left: 15%;
  margin-bottom: 30px;
}

.quiz-form-container .large-button {
  margin-top: 20px;
}

.quiz-container {
  width: 90%;
  padding: 20px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px; /* Adjust the gap between input fields as needed */
}

.question-container {
  width: 90%;
  padding: 20px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-container button {
  padding: 6px 15px;
  border-radius: 15px;
  cursor: pointer;
  background-color: #044a80;
  color: #fff;
  text-align: center;
  margin-top: 2px;
  font-size: 1.1rem;
}

.question-container .input-container .input-row button {
  margin-top: 0px;
  position: absolute;
  left: calc(70% + 16px);
}

.question-container .input-container {
  margin-bottom: 16px;
}

.question-container .input-container input {
  height: 1.6rem;
}

.question-container .input-container .input-row {
  position: relative;
  display: flex;
  align-items: center;
  height: 1.6rem;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-container label {
  margin-bottom: 5px; /* Adjust label spacing as needed */
}

.input-container input {
  width: 70%; /* Input field takes full width of its container */
}

.answer-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin between answer rows as needed */
}

.answer-row input[type="radio"] {
  margin-left: 10px;
  width: 20px; /* Adjust margin between radio button and input field as needed */
}

.answer-row input[type="text"] {
  width: 70%; /* Input field takes up 70% of the remaining space */
}

/* User list and form */

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-list-container {
  width: 80%;
  max-width: 800px;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px; /* Adjust the gap between input fields as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-button {
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #044a80;
  color: #fff;
  max-width: 200px;
  text-align: center;
  font-size: 1.1rem;
}

.small-button:disabled {
  background-color: #9e9e9e;
  color: #fff;
}

.input-error-text,
.error-message {
  color: red;
}

.user-dashboard {
  min-width: 800px;
}

.user-dashboard h2 {
  margin: 0 0 12px 0;
}

.user-table {
  margin-top: 20px;
  max-width: 900px;
  border-collapse: separate;
  border-spacing: 0 20px;
}

.user-table tr {
  margin-bottom: 50px;
}

.user-table td {
  border-radius: 20px;
  white-space: nowrap;
}

.user-table td:first-child {
  text-align: center;
}

.user-table button {
  margin-left: 10px;
}

.user-form-container {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.user-form-container .input-container {
  display: flex;
  align-items: baseline;
}

.user-form-container .input-container input {
  width: 260px;
}

.user-form-container button {
  margin-top: 8px;
  margin-bottom: 10px;
}

/* Location list and form */

.location-list-container {
  width: 70%;
  max-width: 700px;
}

.location-list-container .center-container .small-button {
  margin: 10px;
}

.location-container {
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 16px;
  border-radius: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}

.location-container label {
  font-weight: bold;
}

.button-row-right {
  height: 40px;
  display: flex;
  gap: 11px;
  justify-content: flex-end;
}

.location-data-container {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.location-text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.location-text-container .input-container {
  flex-direction: row;
  height: 21px;
}

.location-text-container .input-container input {
  margin-left: 10px;
  max-width: 400px;
}

.location-text-container .input-container #Kod {
  max-width: 60px;
}

.location-text-container .input-container .input-error-text {
  margin-left: 4px;
  font-size: 0.9rem;
}

.location-number {
  font-weight: bold;
}

.active-edit {
  border-color: #044a80;
}
